
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import NewDashboard from "../vistas/Dashboard/NewDashboard"
import Empresas from "../vistas/Empresas"
import Usuarios from "../vistas/Usuarios"
import Configuracion from "../vistas/Configuracion"
import Fidelizacion from '../vistas/Fidelizacion';
import Movimientos from '../vistas/Acciones';
import Rerportes from '../vistas/Reportes'
import Eds from '../vistas/Eds'
import Servicios from "../vistas/Servicios"
import Kioscos from "../vistas/Kioscos"
import Canastillas from "../vistas/Canastillas"
import Pdv from "app/vistas/PDV/router";
import ClientesPropios from "app/vistas/ClientePropios/router";
import Mantenimiento from "app/vistas/Mantenimiento";
import Transaccion from "app/vistas/Transacciones"
import Parametrizacion from "app/vistas/Parametrizacion";
import Soporte from "app/vistas/Soporte"
import Integraciones from 'app/vistas/Integraciones'
import ConciliacionPDV from "app/vistas/ConciliacionPDV/ConciliacionPDV";
import useAudit from "hooks/useAudit";


let Str = ""
let dCompleto = window.location.href
dCompleto = dCompleto.replace("https://", "").replace("http://", "").replace(":3000", "").replace("//", "/")
const dsplit = dCompleto.split("/")
if (dsplit[1] === "app") {
  Str = "/app"
} else {
  if (dsplit.length > 1 && dsplit[1] !== "app" && dsplit[1] !== "") {
    Str = "/" + dsplit[1] + "/app"
  } else {
    Str = "/app"
  }
}
if (dCompleto.slice(-1) === "/") {
  dCompleto = dCompleto.replace("/", "")
}


const Routes = () => {

    //useAudit();

  return (
    <Switch>
      <Route path={`${Str}/configuracion`} component={Configuracion} />
      <Route path={`${Str}/empresa`} component={Empresas} />
      <Route path={`${Str}/usuarios`} component={Usuarios} />
      <Route path={`${Str}/fidelizacion`} component={Fidelizacion} />
      <Route path={`${Str}/mantenimiento`} component={Mantenimiento} />
      <Route path={`${Str}/acciones`} component={Movimientos} />
      <Route path={`${Str}/eds`} component={Eds} />
      <Route path={`${Str}/reportes`} component={Rerportes} />
      <Route path={`${Str}/servicios`} component={Servicios} />
      <Route path={`${Str}/cc`} component={Kioscos} />
      <Route path={`${Str}/canastilla`} component={Canastillas} />
      <Route path={`${Str}/pdv`} component={Pdv} />
      <Route path={`${Str}/clientes_propios`} component={ClientesPropios} />
      <Route path={`${Str}/transacciones`} component={Transaccion} />
      <Route path={`${Str}/parametrizacion`} component={Parametrizacion} />
      <Route path={`${Str}/soporte`} component={Soporte} />
      <Route path={`${Str}/sap/conciliacion-pdv`} component={ConciliacionPDV} />
      <Route path={`${Str}/integraciones`} component={Integraciones} />
      <Route component={NewDashboard} />
    </Switch >
  )
}



export default withRouter(Routes);
