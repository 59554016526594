import Loader from 'components/Loader'
import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
//rutas


const IntegracionSAP = lazy(async ()=> import('./Sap/index'))
const Apuntamientos = lazy(async()=>import('./Apuntamientos'))

const Integraciones = ({ match }:any) => {

  useEffect(() => {
    console.log(match.url)
  }, [])
  
  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<Loader />}>
          <Route path={`${match.url}/sap`} component={IntegracionSAP} />
          <Route path={`${match.url}/apuntamientos`} component={Apuntamientos} />
        </Suspense>
      </Switch>
    </div>
  )
}

export default Integraciones
